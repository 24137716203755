// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from "react"
import { Link, graphql } from "gatsby"

import { kebabCase } from "lodash"
import "../styles/global.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const TagSection = styled.section`
  padding-top: 30px;
  background-color: #efefef;
`

const TagWrapper = styled.div`
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 1rem;

  @media only screen and (min-width: 75em) {
    margin: auto;
    max-width: 85.2rem;
  }

  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 73.2rem;
  }
`

const TagsPage = ({ data }) => {
  const allTags = data.allMdx.group

  return (
    <Layout>
      <SEO title="Tagged Projects" />
      <TagSection>
        <TagWrapper>
          <h2 className="margin-b-20 slim">ALL TAGS</h2>
          <ul>
            {allTags.map(tag => (
              <li key={tag.fieldValue}>
                <Link
                  to={`/tagged/${kebabCase(tag.fieldValue)}/`}
                  className="p-tag"
                >
                  {tag.fieldValue.toUpperCase()}
                </Link>
                [{tag.totalCount}]
              </li>
            ))}
          </ul>
        </TagWrapper>
      </TagSection>
    </Layout>
  )
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
